import "../Modal.css";
import X from "../../../Media/Black X.png";
import Interns from "../../../Media/Fiserv2-Group-Pic-Interns.jpg";

// Modal about Fiserv Experience
const Fiserv2 = ({ onClickFunction }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="outer">
          <header className="headline-div">
            <h1 className="company-name">Fiserv</h1>
          </header>
          <section className="role">
            <h1 className="role-h1">
              Position: Software Engineer Intern (6/24 - 8/24)
            </h1>
          </section>
          <div className="content">
            <div className="responsibilities">
              <h1 className="responsibilities-h1">Accomplishments</h1>
              {/* List of accomplishments */}
              <ul className="accomplishments-ul">
                <li className="accomplishments-li">
                  Identified an opportunity to improve the department's dispute
                  management platform and presented the idea for a new
                  application for identifying serial chargeback fraud to several
                  technology leaders around the company as a solution,
                  contributing towards reducing $100M+ in chargeback abuse
                  annually.
                </li>
                <li className="accomplishments-li">
                  Developed the full-stack application using Next JS, Java
                  Spring Boot and SQLite serving as a minimum viable product to
                  be transformed into a production level application that can be
                  used by financial institutions (FIs).
                </li>
                <li className="accomplishments-li">
                  Implemented features such as a dashboard for managing
                  suspected chargeback abusers, a cardholder search that
                  provides advanced analytics on cardholders’ prior disputes,
                  and an algorithm leveraging historical disputes data to score
                  cardholders on their likelihood of being a chargeback abuser,
                  aiding FIs in evaluating cardholders.
                </li>
              </ul>
            </div>
            {/* Media Content */}
            <div className="media-content">
              <div className="media-content-div">
                <div className="intern-picture-div">
                  <img
                    src={Interns}
                    alt="Intern Group"
                    className="presentation-picture"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          {/* Exit button */}
          <footer className="button-div">
            <button onClick={onClickFunction} className="ex-button">
              <img src={X} alt="Black X" className="ex-button-img"></img>
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Fiserv2;
