import "../Modal.css";
import X from "../../../Media/Black X.png";
import Cloud from "../../../Media/Cloud Workshop.png";
import CloudPresentation from "../../../Media/PDFs/Intro to Cloud Computing.pdf";
import HackPrincetonHomePage from "../../../Media/HackPrinceton Homepage.png";

// Modal about HackPrinceton Experience
const HackPrinceton = ({ onClickFunction }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="outer">
          <header className="headline-div">
            <h1 className="company-name">HackPrinceton</h1>
          </header>
          <section className="role">
            <h1 className="role-h1">
              Position: Development Team Leader and Software Engineer (9/23 -
              Present)
            </h1>
          </section>
          <div className="content">
            <div className="responsibilities">
              <h1 className="responsibilities-h1">Accomplishments</h1>
              {/* List of accomplishments */}
              <ul className="accomplishments-ul">
                <li className="accomplishments-li">
                  Spearheaded an initiative to rewrite the HackPrinceton
                  website, a full stack application used by 2,500+ people
                  annually.
                </li>
                <li className="accomplishments-li">
                  Recruited and led a team of 8 developers in developing the new
                  website, conducting important discussions among the team
                  regarding technological architecture and design decisions.
                </li>
                <li className="accomplishments-li">
                  Developed the frontend for the website using React JS
                  implementing responsive UI for authentication, navigation, a
                  status dashboard, an application page, and an admin panel
                  containing features for controlling all relevant hackathon
                  details such as managing users and hackathon dates.
                </li>
                <li className="accomplishments-li">
                  Delivered a 45-minute presentation on cloud computing at the
                  hackathon with 40+ people in attendance.
                </li>
              </ul>
            </div>
            {/* Media content */}
            <div className="media-content">
              <div className="media-content-div">
                <div className="media-content-div">
                  <a
                    href="https://my.hackprinceton.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={HackPrincetonHomePage}
                      alt="HackPrinceton Home Page"
                      className="presentation-picture"
                    ></img>
                  </a>
                  <p>Click on picture to view website</p>
                </div>
                <a
                  href={CloudPresentation}
                  download="Intro to Cloud Computing.pdf"
                >
                  <img
                    src={Cloud}
                    alt="Cloud Computing Workshop"
                    className="presentation-picture"
                  ></img>
                </a>
                <p>Click on picture to view presentation</p>
              </div>
            </div>
          </div>
          <footer className="button-div">
            <button onClick={onClickFunction} className="ex-button">
              <img src={X} alt="Black X" className="ex-button-img"></img>
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default HackPrinceton;
