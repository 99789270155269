import "../Modal.css";
import X from "../../../Media/Black X.png";
import RBCPic from "../../../Media/RBC-logo.jpg";

// Modal about Fiserv Experience
const RBC = ({ onClickFunction }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="outer">
          <header className="headline-div">
            <h1 className="company-name">Royal Bank of Canada</h1>
          </header>
          <section className="role">
            <h1 className="role-h1">
              Position: Incoming Global Markets Quantitative Trading Summer
              Analyst (6/25 - 8/25)
            </h1>
          </section>
          <div className="content">
            <div className="responsibilities">
              <h1 className="responsibilities-h1">Program Description</h1>
              {/* List of accomplishments */}
              <ul className="accomplishments-ul">
                <li className="accomplishments-li">
                  RBC’s quantitative trading teams are traders who program
                  computers to trade for them. The Quantitative Trading Summer
                  Analyst Program offers an unrivaled opportunity to work in one
                  of these highly technical algorithmic trading groups for the
                  summer, leading to a potential full-time offer. The teams are
                  made up of mathematicians, software engineers, and data
                  scientists who write and manage automated trading strategies.
                  RBC’s strategic objective is to find excellent candidates with
                  a STEM/Computer Science background, hire them onto our
                  platform, and promote the ongoing development of this junior
                  cohort to become leaders in this space.
                </li>
              </ul>
            </div>
            {/* Media Content */}
            <div className="media-content">
              <div className="media-content-div">
                <div className="intern-picture-div">
                  <img
                    src={RBCPic}
                    alt="RBC Logo"
                    className="presentation-picture"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          {/* Exit button */}
          <footer className="button-div">
            <button onClick={onClickFunction} className="ex-button">
              <img src={X} alt="Black X" className="ex-button-img"></img>
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default RBC;
